import { default as about_45abcsTjIPcK7CgMeta } from "/opt/buildhome/repo/pages/about-abc.vue?macro=true";
import { default as accessibilityXr1RkVknkKMeta } from "/opt/buildhome/repo/pages/accessibility.vue?macro=true";
import { default as add_45payment_45methodWtOm530NngMeta } from "/opt/buildhome/repo/pages/account/add-payment-method.vue?macro=true";
import { default as details864qnklwbnMeta } from "/opt/buildhome/repo/pages/account/details.vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as my_45ordersWZNfBusvnXMeta } from "/opt/buildhome/repo/pages/account/my-orders.vue?macro=true";
import { default as my_45wishlistpuWDAZFeFtMeta } from "/opt/buildhome/repo/pages/account/my-wishlist.vue?macro=true";
import { default as submit_45a_45po7Gy1gkgTkhMeta } from "/opt/buildhome/repo/pages/account/submit-a-po.vue?macro=true";
import { default as _91article_93whGM1zMuGxMeta } from "/opt/buildhome/repo/pages/articles/[article].vue?macro=true";
import { default as indexN5aA9NpkoaMeta } from "/opt/buildhome/repo/pages/articles/index.vue?macro=true";
import { default as _91page_93WJrofJzDM8Meta } from "/opt/buildhome/repo/pages/articles/page/[page].vue?macro=true";
import { default as _91career_93qYknMGCLN0Meta } from "/opt/buildhome/repo/pages/careers/[career].vue?macro=true";
import { default as indexvCVSP6w0UQMeta } from "/opt/buildhome/repo/pages/careers/index.vue?macro=true";
import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as _91state_931r5M8nQn1EMeta } from "/opt/buildhome/repo/pages/catalog/[state].vue?macro=true";
import { default as indexbIBP9ziIqeMeta } from "/opt/buildhome/repo/pages/catalog/index.vue?macro=true";
import { default as confirmationgKeEKg3lrJMeta } from "/opt/buildhome/repo/pages/checkout/confirmation.vue?macro=true";
import { default as indexQrPIrenMTVMeta } from "/opt/buildhome/repo/pages/checkout/index.vue?macro=true";
import { default as _91step_93i6ZUvmN61NMeta } from "/opt/buildhome/repo/pages/checkout/step/[step].vue?macro=true";
import { default as create_45accountOVc1ya8VluMeta } from "/opt/buildhome/repo/pages/create-account.vue?macro=true";
import { default as documents_45for_45orderingVVF2a6BU2tMeta } from "/opt/buildhome/repo/pages/documents-for-ordering.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as _91_46_46_46slug_93Bz0icsTi2sMeta } from "/opt/buildhome/repo/pages/free-trial/[...slug].vue?macro=true";
import { default as thank_45you7NnNpbDNjfMeta } from "/opt/buildhome/repo/pages/free-trial/thank-you.vue?macro=true";
import { default as _91state_93mJtx28T2OLMeta } from "/opt/buildhome/repo/pages/home/[state].vue?macro=true";
import { default as indexkUOxkEycS8Meta } from "/opt/buildhome/repo/pages/home/index.vue?macro=true";
import { default as how_45to_45ordercl7TB7fixzMeta } from "/opt/buildhome/repo/pages/how-to-order.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_93ua66axcsQwMeta } from "/opt/buildhome/repo/pages/products/[...slug].vue?macro=true";
import { default as indexKfkHcrpqWUMeta } from "/opt/buildhome/repo/pages/products/[product]/index.vue?macro=true";
import { default as indexfRsRvEd4TiMeta } from "/opt/buildhome/repo/pages/products/index.vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as share_45wishlist6P2pelpUkOMeta } from "/opt/buildhome/repo/pages/share-wishlist.vue?macro=true";
import { default as terms_45of_45useAAGTgp8alZMeta } from "/opt/buildhome/repo/pages/terms-of-use.vue?macro=true";
import { default as test_45auto_45complete8sIpH6GNdEMeta } from "/opt/buildhome/repo/pages/test-auto-complete.vue?macro=true";
import { default as component_45stubIs70BB2SVfMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubIs70BB2SVf } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-abc",
    path: "/about-abc",
    component: () => import("/opt/buildhome/repo/pages/about-abc.vue").then(m => m.default || m)
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/opt/buildhome/repo/pages/accessibility.vue").then(m => m.default || m)
  },
  {
    name: "account-add-payment-method",
    path: "/account/add-payment-method",
    component: () => import("/opt/buildhome/repo/pages/account/add-payment-method.vue").then(m => m.default || m)
  },
  {
    name: "account-details",
    path: "/account/details",
    component: () => import("/opt/buildhome/repo/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/opt/buildhome/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-my-orders",
    path: "/account/my-orders",
    component: () => import("/opt/buildhome/repo/pages/account/my-orders.vue").then(m => m.default || m)
  },
  {
    name: "account-my-wishlist",
    path: "/account/my-wishlist",
    component: () => import("/opt/buildhome/repo/pages/account/my-wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-submit-a-po",
    path: "/account/submit-a-po",
    component: () => import("/opt/buildhome/repo/pages/account/submit-a-po.vue").then(m => m.default || m)
  },
  {
    name: "articles-article",
    path: "/articles/:article()",
    component: () => import("/opt/buildhome/repo/pages/articles/[article].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexN5aA9NpkoaMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-page-page",
    path: "/articles/page/:page()",
    meta: _91page_93WJrofJzDM8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/articles/page/[page].vue").then(m => m.default || m)
  },
  {
    name: "careers-career",
    path: "/careers/:career()",
    meta: _91career_93qYknMGCLN0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/careers/[career].vue").then(m => m.default || m)
  },
  {
    name: "careers",
    path: "/careers",
    meta: indexvCVSP6w0UQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/careers/index.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: "catalog-state",
    path: "/catalog/:state()",
    component: () => import("/opt/buildhome/repo/pages/catalog/[state].vue").then(m => m.default || m)
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/opt/buildhome/repo/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-confirmation",
    path: "/checkout/confirmation",
    component: () => import("/opt/buildhome/repo/pages/checkout/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/opt/buildhome/repo/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-step-step",
    path: "/checkout/step/:step()",
    component: () => import("/opt/buildhome/repo/pages/checkout/step/[step].vue").then(m => m.default || m)
  },
  {
    name: "create-account",
    path: "/create-account",
    meta: create_45accountOVc1ya8VluMeta || {},
    component: () => import("/opt/buildhome/repo/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: "documents-for-ordering",
    path: "/documents-for-ordering",
    component: () => import("/opt/buildhome/repo/pages/documents-for-ordering.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "free-trial-slug",
    path: "/free-trial/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/free-trial/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "free-trial-thank-you",
    path: "/free-trial/thank-you",
    component: () => import("/opt/buildhome/repo/pages/free-trial/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "home-state",
    path: "/home/:state()",
    meta: _91state_93mJtx28T2OLMeta || {},
    component: () => import("/opt/buildhome/repo/pages/home/[state].vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: indexkUOxkEycS8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: "how-to-order",
    path: "/how-to-order",
    meta: how_45to_45ordercl7TB7fixzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/how-to-order.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/products/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/pages/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "products-product",
    path: "/products/:product()",
    component: () => import("/opt/buildhome/repo/pages/products/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/opt/buildhome/repo/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "share-wishlist",
    path: "/share-wishlist",
    component: () => import("/opt/buildhome/repo/pages/share-wishlist.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/opt/buildhome/repo/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "test-auto-complete",
    path: "/test-auto-complete",
    component: () => import("/opt/buildhome/repo/pages/test-auto-complete.vue").then(m => m.default || m)
  },
  {
    name: component_45stubIs70BB2SVfMeta?.name,
    path: "/account/",
    component: component_45stubIs70BB2SVf
  }
]