import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/opt/buildhome/repo/middleware/auth.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  guest: () => import("/opt/buildhome/repo/middleware/guest.ts")
}