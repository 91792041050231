import revive_payload_client_Y6HsqueFS9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_utbE5AgxgD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_nrr6XYOXT6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_NQ0TvLfjhV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.3_@vue+compiler-core@3.4.29_axios@1.7.2_nuxt@3.12._62e3tzp2nxx6hbq3rdcqqd7lny/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_ZpvGJxc69M from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_4kBgZkvEA2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_2XgblZoQZM from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_t8DNR5kqbv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_0wr4AJ5Bf7 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+algolia@1.10.2_vue@3.4.27/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import script_26uK68GsIS from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+turnstile@0.3.0/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import chunk_reload_client_mQM1nnxj44 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.8.0_@unocss+reset@0.61.0_axios@1.7.2_eslint@8.57.0_floating-_mgwdgx2465ncmsnllapbdoeqqq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_9RNjyLX3El from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import error_handler_kEP5FliEXj from "/opt/buildhome/repo/plugins/error-handler.ts";
import floating_vue_BiOD74u9sH from "/opt/buildhome/repo/plugins/floating-vue.ts";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import vueGoogleMaps_8xmoAwKZvr from "/opt/buildhome/repo/plugins/vueGoogleMaps.ts";
export default [
  revive_payload_client_Y6HsqueFS9,
  unhead_utbE5AgxgD,
  router_nrr6XYOXT6,
  _0_siteConfig_NQ0TvLfjhV,
  payload_client_ZpvGJxc69M,
  check_outdated_build_client_4kBgZkvEA2,
  plugin_vue3_2XgblZoQZM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t8DNR5kqbv,
  plugin_0wr4AJ5Bf7,
  script_26uK68GsIS,
  chunk_reload_client_mQM1nnxj44,
  plugin_9RNjyLX3El,
  error_handler_kEP5FliEXj,
  floating_vue_BiOD74u9sH,
  sentry_3AyO8nEfhE,
  vueGoogleMaps_8xmoAwKZvr
]